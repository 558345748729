/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Puede parecer un poco decepcionante para el que se ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2011/07/06/iniciandome-en-open-street-map/"
  }, "inicia en los mapas libres y colaborativos"), " como ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2010/05/09/open-street-map/"
  }, "OSM"), " que cuando entras en su página oficial ", React.createElement(_components.a, {
    href: "http://www.openstreetmap.org/"
  }, "openstreetmaps.org"), " no se encuentren las mismas facilidades que en los ", React.createElement(_components.a, {
    href: "https://www.google.es/maps/"
  }, "servicios de mapas privados"), ". No és que estén más atrasados o no quieran estas herramientas, es que esa web se dedica exclusivamente a promocionar Open Street Maps como lo que es, una base de datos geográficos. Y para obtener todos esos servicios nos tenemos que ir a otras webs que sí lo ofrecen, además también de forma libre y colaborativa. La lástima es no poder encontrarlos todos integrados en una misma web, creo que publicitaría mucho más este tipo de servicios, todo es ponerse de acuerdo, el código y las APIs están ahí. Vamos a verlos:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.project-osrm.org/"
  }, "OSRM"), ": Un motor de calculo de rutas desarrollado por la comunidad OSM, como no, es código abierto y no funciona nada mal."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://tracker.geops.ch/?z=4&s=1&x=758494.8241&y=6055060.5301&l=transport"
  }, "TRAVIC"), ": En esta web se han tomado feeds públicos con la información del transporte público de varias ciudades y se han situado sobre el mapa, por lo que es posible visualizar por donde va el autobús que quieres coger."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://opentopomap.org"
  }, "OpenTopoMap"), ": Un mapa físico de toda la vida con algún toque de OSM, la verdad es que impresiona verlo."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.mapillary.com/map/im/xOgis-3MFtu4MV5ceieQtA"
  }, "Mapillary"), ": Proyecto de alternativa colaborativa al Street View de Google. Muy vacío por el momento, pero es fácil que se vaya rellenando rápidamente con aportaciones de nuevos usuarios."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://wiki.openstreetmap.org/wiki/OpenAerialMap"
  }, "OpenAerialMap"), ": Por último un servicio que no ha acabado de despegar es el mapa aéreo libre, hubo una iniciativa por abrirlo en 2007 pero ", React.createElement(_components.a, {
    href: "http://lwn.net/Articles/338491/"
  }, "la cosa no funcionó"), " y ahora mismo no hay nada funcional que se le parezca, toda una lástima por que con la moda de los drones un servicio de este tipo podría llenarse de fotografías de usuarios que mostraran la tierra en alta resolución."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
